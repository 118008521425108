jQuery(document).ready(function($) {

    const Dot = {
        activeClass : 'active',
        activeSize : 11,
        defaultSize : 6
    }

    let $defaultActive = $('.loc__body.show');

    if ($defaultActive.length > 0) {
        let $defaultDot = findDot($defaultActive);
        activeDot($defaultDot);
    }
    $('.loc__body').on('show.bs.collapse', activateDotOnShow);
    $('.loc__body').on('hide.bs.collapse', deactivateDotOnHide);


    

    function activateDotOnShow()
    {
        $dot = findDot(this);
        if ($dot) {
            activeDot($dot);
        }
    }

    function deactivateDotOnHide() {
        $dot = findDot(this);
        if ($dot) {
            $dot.removeClass(Dot.activeClass).attr('r', Dot.defaultSize);
        }
    }
    
    function activeDot(dot) {
        let $dot = dot instanceof jQuery ? dot : $(dot);

        $('svg .location-dot').each(function() { 
            $(this).removeClass(Dot.activeClass).attr('r', Dot.defaultSize)
        });
        $dot.addClass(Dot.activeClass).attr('r', Dot.activeSize);
    }

    function findDot(location) {
        let $location = location instanceof jQuery ? location : $(location);

        if ($location.data('dot')) {
            let state = $location.data('dot');
            let $dot = $(`svg .location-dot#${state}`);

            if ($dot.length > 0 && typeof $dot != 'undefined') {
                return $dot;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }
});