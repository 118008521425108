import "bootstrap";
import "owl-slider/js/owl.total";
import "./_map";
import "./_woocommerce-accordion";

jQuery(document).ready(function ($) {
    let $carousels = $(".owl-carousel");

    $carousels.each(function () {
        let $carousel = $(this);
        var args;

        if ($carousel.hasClass("carousel--location")) {
            args = {
                items: 1,
                dots: true,
                nav: true,
                navText: [
                    "<span class='sr-only'>Previous Slide</span>",
                    "<span class='sr-only'>Next Slide</span>",
                ],
                loop: true,
                dotData: true,
            };
        } else {
            args = {
                margin: 22,
                nav: true,
                navText: [
                    "<span class='the-arrow -left'><span class='shaft'></span></span>",
                    "<span class='the-arrow -right'><span class='shaft'></span></span>",
                ],
                dots: false,
                loop: true,
                autoplay: true,
                autoplayTimeout: 6000,
                responsive: {
                    0: {
                        items: 1,
                    },
                    768: {
                        items: 4,
                    },
                },
            };
        }

        $carousel.owlCarousel(args);
    });

    $(".js-event").on("shown.bs.collapse", function (e) {
        var $button = $(`button[data-target="#${$(this).attr("id")}"]`);
        var collapseText = $(this).data("collapse-text");
        $button.text(collapseText);
    });

    $(".js-event").on("hidden.bs.collapse", function (e) {
        var $button = $(`button[data-target="#${$(this).attr("id")}"]`);
        var showText = $(this).data("show-text");
        $button.text(showText);
    });

    $(".trailer--toggle").on("click", function () {
        event.preventDefault();
        $(".trailer--toggle").removeClass("active");
        $(this).addClass("active");

        var targetID = "#" + $(this).attr("data-target");
        console.log(targetID);
        $(".trailer--iframe").addClass("collapse");
        $(targetID).removeClass("collapse");
    });

    $("#location-contact").click(function () {
        $([document.documentElement, document.body]).animate(
            {
                scrollTop: $(".block--cta-with-form").offset().top,
            },
            2000
        );
    });

    $(".btn--product--contact").each(function () {
        let button = $(this);

        button.on("click", function () {
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(".block--cta-with-form").offset().top,
                },
                1500
            );
        });
    });

    $(".btn--page-scroll").each(function () {
        let button = $(this);
        button.on("click", function () {
            let buttonTarget = $(this).data("target");
            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(buttonTarget).offset().top,
                },
                1500
            );
        });
    });

    /*
     * Initializes and activates Careers and Product Listing Filter View
     */
    var $grid = $(".grid--listing");

    $grid.isotope({
        filter: "*",
        itemSelector: ".grid--item",
    });

    $grid.imagesLoaded().progress(function () {
        $grid.isotope("layout");
    });

    /*
     * Store filter for each group
     */
    var filters = {};

    $(".grid--navigation").on("click", "button", function (event) {
        var $button = $(event.currentTarget);
        var $buttonGroup = $button.parents(".grid--navigation__filter");
        var $filterGroup = $buttonGroup.attr("data-filter-group");

        filters[$filterGroup] = $button.attr("data-filter");

        var $filterValue = concatFilterValues(filters);

        $grid.isotope({ filter: $filterValue });
    });

    /*
     * Change active class on buttons
     */
    $(".grid--navigation__filter").each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on("click", "button", function (event) {
            $buttonGroup.find(".active").removeClass("active");
            var $button = $(event.currentTarget);
            $button.addClass("active");
        });
    });

    /*
     * Flatten object by concatinating values
     */
    function concatFilterValues(obj) {
        var value = "";
        for (var prop in obj) {
            value += obj[prop];
        }
        return value;
    }

    // Adds slider functionality to main product gallery

    $(document).ready(function () {
        $("#carousel").flexslider({
            animation: "slide",
            controlNav: false,
            animationLoop: false,
            slideshow: false,
            itemWidth: 150,
            itemMargin: 5,
            asNavFor: "#slider",
        });

        // Adds slider functionality to product gallery carousel

        $("#slider").flexslider({
            animation: "slide",
            controlNav: false,
            animationLoop: false,
            slideshow: false,
            sync: "#carousel",
        });
    });
});
