var accordion = {
    init: function () {
        this.cacheDom();
        this.initElements();
        jQuery(window).click(this.deligate);
    },

    cacheDom: function () {
        this.$main = jQuery(".woocommerce_widget .product-categories");
        this.$topLvl = this.$main.children();
        this.$parent = this.$main.find(".cat-parent");
        this.$subCat = this.$main.find(".children");
    },

    appendIcon: function ($el) {
        $el.find("a")
            .first()
            .after(
                '<img src="/wp-content/themes/werts/images/icons/arrow_down.png" class="accordion-icon">'
            );
        $el.find("a").first().addClass("accordion__top--link");
        // console.log($el);
    },

    initElements: function () {
        this.$subCat.hide();
        this.$topLvl.addClass("accordion__top");
        this.$parent.each(function () {
            var $el = jQuery(this);
            accordion.appendIcon($el);
            if ($el.is(".current-cat, .current-cat-parent")) {
                var $subCat = $el.children(".children");
                $subCat.parent().children(".accordion-icon").addClass("open");
                $subCat.show().addClass("accordion-expand");
            }
        });
    },

    deligate: function (e) {
        var $clicked = jQuery(e.target);
        // console.log($clicked);
        var $parent = $clicked.parent().hasClass("accordion-icon")
            ? $clicked.parent().parent()
            : $clicked;

        if ($clicked.hasClass("accordion__top--link")) {
            if ($clicked.hasClass("open")) {
                console.log("here");
            } else {
                e.preventDefault();
            }
            $parent = $clicked.parent();
        }

        var $subCat = $parent.children(".children");

        if ($subCat.hasClass("accordion-expand")) {
            // console.log("close action");
            accordion.collapseEl($subCat, $parent);
        } else if ($parent.hasClass("cat-parent")) {
            // console.log("open action");
            accordion.collapseAll($parent);
            accordion.expandEl($subCat, $parent);
        }
    },

    expandEl: function ($subCat, $parent) {
        // console.log($parent);
        $parent.children(".accordion-icon").addClass("open");
        $parent.find(".accordion__top--link").first().addClass("open");
        $subCat.slideDown().addClass("accordion-expand");
    },

    collapseEl: function ($subCat, $parent) {
        $parent.children(".accordion-icon").removeClass("open");
        $parent.find("a.accordion__top--link").first().removeClass("open");
        $subCat.slideUp().removeClass("accordion-expand");
    },

    collapseAll: function ($parent) {
        if (!$parent.hasClass("accordion__top")) {
            return false;
        }
        this.$subCat.slideUp().removeClass("accordion-expand");
        // console.log(this.$parent);
        this.$parent.find("a.accordion__top--link").first().removeClass("open");
        this.$parent.children(".accordion-icon").removeClass("open");
    },
};

jQuery(window).on("load", function () {
    if (jQuery(".woocommerce_widget .product-categories").length) {
        // console.log("matt");
        accordion.init();
    }
});
